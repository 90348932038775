import React from "react";

function SearchMediaIcon() {
  return (
    <div >
      <span  >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="200"
          height="200"
          viewBox="0 0 640 550"
        >
          <defs>
            <path
              id="fVpncTD1"
              d="M384.7 525.38c-12 6.92-26.78 6.92-38.77 0-32.64-18.85-293.71-169.57-326.35-188.41-7.5-4.35-10.05-13.95-5.7-21.45 1.37-2.37 3.34-4.34 5.7-5.71 24.24-14.06 218.08-126.55 242.31-140.62 6.3-3.64 14.07-3.64 20.37 0 33.85 19.54 304.59 175.87 338.43 195.41 6.78 3.92 9.09 12.59 5.16 19.37a14.18 14.18 0 01-5.14 5.15c-47.2 27.25-212.41 122.63-236.01 136.26z"
            ></path>
            <path
              id="aoqTt5NIe"
              d="M112.8 343.92l38.12-5.71 25 11.24-63.68 41.01-40.14-23.17 40.7-23.37z"
            ></path>
            <linearGradient
              id="gradiente1lLiAnE7V"
              x1="208.36"
              x2="111.4"
              y1="295.83"
              y2="367.71"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0%" stopColor="#f15a24"></stop>
              <stop offset="100%" stopColor="#ffc963"></stop>
            </linearGradient>
            <path
              id="a5malkJjIo"
              d="M212.79 400.21l34.07-8.4 32.32 10.14-66.94 44.81-40.13-23.18 40.68-23.37z"
            ></path>
            <linearGradient
              id="gradientc3bS4XJOAa"
              x1="314.41"
              x2="212.38"
              y1="346.97"
              y2="422.59"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0%" stopColor="#f15a24"></stop>
              <stop offset="100%" stopColor="#ffc963"></stop>
            </linearGradient>
            <path
              id="alJ6j40aN"
              d="M302.68 442.35l39.39-10.85 23.72 16.37-63.68 41.02-40.13-23.17 40.7-23.37z"
            ></path>
            <linearGradient
              id="gradienta3dqXHL3cm"
              x1="398.23"
              x2="301.28"
              y1="394.26"
              y2="466.14"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0%" stopColor="#f15a24"></stop>
              <stop offset="100%" stopColor="#ffc963"></stop>
            </linearGradient>
            <path
              id="b1ir0iGkc"
              d="M224.01 300.19l51.49 30.4 86.39-52.64-3.74-12.74-31.16-8.26-102.98 43.24z"
            ></path>
            <linearGradient
              id="gradientc5HvZYdSB"
              x1="451.43"
              x2="292.19"
              y1="192.3"
              y2="289.54"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0%" stopColor="#f15a24"></stop>
              <stop offset="100%" stopColor="#ffc963"></stop>
            </linearGradient>
            <path
              id="a1vXhXEgFy"
              d="M494.57 439.78l-34.23-18.63 96.23-47.85 27.05 15.52-89.05 50.96z"
            ></path>
            <linearGradient
              id="gradientb8vjqWlNh"
              x1="601.89"
              x2="514.66"
              y1="363.43"
              y2="410.42"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0%" stopColor="#f15a24"></stop>
              <stop offset="100%" stopColor="#ffc963"></stop>
            </linearGradient>
            <path
              id="b9sTtx8KL"
              d="M259.62 386.76a18.331 18.331 0 01-9.27 3.14c-4.01.58-8.09-.35-11.46-2.61-.07.62-.62 5.54-.69 6.15-.39 4.73 5.79 3.79 8.13 3.69a69.2 69.2 0 0013.5-2.08c1.77-.42 7.41-1.32 7.73-4.21.32-2.89-.62-3.49-3.99-3.9-.74 0-3.08-.18-3.95-.18z"
            ></path>
            <path
              id="a1gAxzjMuA"
              d="M252.87 369.98s-3.54 16.66-1.91 16.59l12.61.37a13.545 13.545 0 01-10.63 7.51c-8.17 1.19-14-7.09-14-7.09.22-1.38 1.3-8.31 3.25-20.78"
            ></path>
            <path
              id="e2kxDlCZGK"
              d="M237.51 362.27c-1.46 7.96-2.27 12.37-2.43 13.26 0 0 3.81 3.91 16.64 3.45v-23.42"
            ></path>
            <path
              id="c1lqxiZUpv"
              d="M230.01 330.22s14.32-11.48 23.82-12.84c0 0 14.45 8.96 13.78 15.85l-23.42 10.63"
            ></path>
            <path
              id="d3rB3QfWWi"
              d="M272.94 396.68a18.297 18.297 0 01-9.26 3.14c-4.01.58-8.1-.34-11.47-2.6l-.7 6.07c-.39 4.73 5.79 3.8 8.13 3.69 4.56-.25 9.08-.94 13.5-2.07 1.77-.43 7.41-1.33 7.73-4.22.31-2.89-.59-3.44-3.97-3.83-.75 0-3.09-.18-3.96-.18z"
            ></path>
            <path
              id="a73wmlhbUc"
              d="M266.19 379.9s-3.54 16.66-1.91 16.59l12.62.37a13.524 13.524 0 01-10.64 7.51c-8.16 1.19-13.99-7.08-13.99-7.08.21-1.39 1.29-8.32 3.24-20.79"
            ></path>
            <path
              id="djs1en5b"
              d="M230.92 330.75s14.31-11.48 23.81-12.84c0 0 14.46 8.96 13.79 15.86l-23.43 10.63"
            ></path>
            <path
              id="b3wAoFbbq8"
              d="M238.55 334.58c1.77-1.77 26.16-13.06 34.71-7.88 8.56 5.17 8.03 9.01 6.95 15.32-1.08 6.31-9.36 40.29-9.36 40.29a30.897 30.897 0 01-19.49.93l2.4-25.25-32.38-17.72"
            ></path>
            <path
              id="aEzXLbFQ5"
              d="M246.42 337.72l32.76-16.85-21.9-15.58-30.69 17.4 12.16 9.27 7.67 5.76z"
            ></path>
            <path
              id="bQfTT82rM"
              d="M157.72 325.88c-1.15 5.32-1.79 8.27-1.91 8.86 2.55-.51 5.14-.78 7.74-.8 1.98.27 4.52 3.79 2.04 6.19-2.48 2.39-6.7 2.16-10.94 3.15-4.23.99-4.89 1.19-7.6.43-2.71-.77-3.12-4.45-3.12-4.45.15-1.03.86-6.18 2.13-15.45"
            ></path>
            <path
              id="gACAZGq84"
              d="M143.72 317.25c-1.45 7.95-2.26 12.37-2.43 13.25 0 0 3.81 3.92 16.64 3.46v-23.42"
            ></path>
            <path
              id="b1oArbGO4M"
              d="M145.33 348.13c0 3.75-8.17 6.79-18.26 6.79-10.09 0-18.27-3.04-18.27-6.79 0-3.74 8.18-6.78 18.27-6.78s18.26 3.04 18.26 6.78z"
            ></path>
            <path
              id="f4i6siBwAJ"
              d="M123.98 317.78l.71 27.33h5.46v-27.33"
            ></path>
            <path
              id="e13gRBqPhf"
              d="M172.66 333.14c-1.15 5.32-1.79 8.27-1.92 8.86 2.55-.5 5.15-.77 7.75-.79 1.98.26 4.51 3.79 2.03 6.18-2.48 2.39-6.69 2.16-10.93 3.15-4.23 1-4.89 1.19-7.6.43s-3.12-4.45-3.12-4.45c.14-1.03.85-6.18 2.13-15.45"
            ></path>
            <path
              id="eNAHD5kAV"
              d="M302.62 123.05l5.25-8.47-20.27-9.85-13.01 26.69 18.94 8.11 9.09-16.48z"
            ></path>
            <path
              id="cbBoxRNNm"
              d="M308.91 130.09c-.47-.42-.89-.89-1.25-1.4-.31-.86-.43-1.78-.34-2.69-.1-1.33-.36-2.64-.76-3.9a1.582 1.582 0 00-3.09.42v2.47c-.14-.13-1.26-1.14-1.4-1.26-1.89 3.43-3.84 6.85-5.91 10.2h3.43c4.12 2.45 7.78 5.61 10.81 9.32a1.472 1.472 0 002.48-.51c.17-.03.33-.09.48-.16.73-.45.98-1.39.57-2.14a1.34 1.34 0 00-.78-.75c-.32-.37-.62-.74-.92-1.13.02.02.17.19.19.21 1.4 1.45 3.62-.76 2.27-2.27-1.16-1.28-5.2-5.77-5.78-6.41z"
            ></path>
            <path
              id="cIZAZrJ8A"
              d="M301.72 128.58c.06-1.07.47-2.1 1.17-2.92.27-.39-.35-.75-.62-.36-.77.93-1.21 2.08-1.26 3.28.15 1.21.59 2.36 1.29 3.35.13.15.35.17.5.04.12-.09.16-.25.11-.39a6.705 6.705 0 01-1.19-3z"
            ></path>
            <path
              id="b24y1pIgvx"
              d="M321.28 157.75c.01 4.03 2.18 7.74 5.69 9.73C347.4 179.03 510.84 271.45 531.27 283c5.37 3.04 12.19 1.16 15.23-4.22.96-1.68 1.46-3.58 1.46-5.51v-119.1c-.02-3.93-2.09-7.56-5.46-9.57-20.43-12.2-183.88-109.79-204.31-121.99-5.31-3.16-12.17-1.43-15.33 3.88a11.197 11.197 0 00-1.58 5.72v125.54z"
            ></path>
            <path
              id="g2hXsoigfj"
              d="M402.26 128.46c.61-.46 1.02-1.13 1.17-1.88.16-.73-.3-1.46-1.04-1.63a1.37 1.37 0 00-.93.12 484.37 484.37 0 01-7.2 3.55c.09-.32.14-.5.15-.53.39-1.14.53-2.35.42-3.55-.51-2.02-2.34-.9-3.08.69-1.01 2.13-1.22 4.42-3.14 6.1a9.514 9.514 0 01-3.72 1.5c-1.35.39-2.69.75-4.04 1.1-.71.23-1.1.98-.88 1.69.13.42.46.75.88.88-.6.4-.78 1.21-.38 1.82.13.19.3.35.51.45-.61.98.08 2.55 1.38 2.02 3.16-1.28 6.43-2.25 9.78-2.89 4.94-.26 8.87-.75 9.85-.83 1.68-.2 1.77-2.82 0-2.68h-.25c1.78-.29 2.76-.46 2.96-.49 1.47-.25 1.15-2.82-.35-2.62h-.36l-.97.14c.84-.28 1.3-.43 1.4-.46.87-.3 1.66-.5 2.11-1.37a1.36 1.36 0 00-.71-1.77c-.29-.12-.61-.14-.91-.05-.53.14-2.39.62-2.65.69z"
            ></path>
            <path
              id="doPCy5CVu"
              d="M393.88 129.91c-.11.05-.17.17-.16.28.47 1.72-.08 3.56-1.42 4.74-.29.24.14.67.42.42a5.293 5.293 0 001.56-5.31c-.08-.15-.26-.21-.4-.13z"
            ></path>
            <path
              id="k2L5HBiRVF"
              d="M396.02 130c-.36.12-.2.71.16.57 3.17-1.12 5.77-1.79 6.41-1.99.15-.08.21-.26.13-.4a.298.298 0 00-.29-.16c-3.24.85-5.77 1.78-6.41 1.98z"
            ></path>
            <path
              id="a6IgRkC69j"
              d="M397.12 132.11c-.37 0-.22.64.16.57.68-.11 6.14-.95 6.82-1.05.37-.05.21-.64-.16-.57-1.37.21-6.14.94-6.82 1.05z"
            ></path>
            <path
              id="b74QoBlbGI"
              d="M397.21 134.34c-.39 0-.39.6 0 .6 2.76 0 4.96-.22 5.51-.24.37-.04.37-.62 0-.61-2.75.18-4.96.23-5.51.25z"
            ></path>
            <path
              id="bkhmI038r"
              d="M99.48 248.1l32.57-17.63V94.31l-32.57 18.66V248.1z"
            ></path>
            <path
              id="c1UpaucV2E"
              d="M144.57 201.25l32.57-17.65V47.45l-32.57 18.67v135.13z"
            ></path>
            <path
              id="d2i8ENPMLJ"
              d="M575.88 367.7c-4.12-1.4-8.58-1.4-12.7 0-.58.17-.9.77-.73 1.34.1.35.38.63.73.73a22.32 22.32 0 0012.65.16c.48.18.94.37 1.4.6 1.1.53 2.07-.85 1.43-1.61.12-.05 1.04-.46 1.16-.51 1.24-.55.14-2.47-1.1-1.85-.92.45-1.87.83-2.84 1.14z"
            ></path>
            <path
              id="delUDozIL"
              d="M154.94 270.37c-5.32 3.06-11.87 2.23-17.56.99-1.14-.25-1.61 1.49-.48 1.77 6.18 1.35 13.22 2.16 18.96-1.17 5.32-3.12 8.03-9.11 9.74-14.79a.932.932 0 00-.63-1.17.937.937 0 00-1.17.64c-1.58 5.31-3.95 10.84-8.86 13.73z"
            ></path>
            <path
              id="cLFpYnzM4"
              d="M145.64 281.16c-.25.02-.48.14-.64.33-.96-2.88-1.47-5.27-1.63-5.86-.25-1.13-1.99-.66-1.77.48 1.02 4.51 2.54 8.9 4.53 13.07.5 1.05 2.04.15 1.56-.9a66.38 66.38 0 01-2.21-5.31h.12c8.58-1.01 15.33-2.56 17.03-2.84 1.17-.28.69-2-.44-1.75-8.21 1.77-14.9 2.5-16.55 2.78z"
            ></path>
            <path
              id="a9uBzBoNoM"
              d="M141.86 297.41c-.9.72.37 2 1.28 1.27 2.32-1.88 4.29-4.15 5.83-6.71a.91.91 0 00-.32-1.24.892.892 0 00-1.24.32 24.275 24.275 0 01-5.55 6.36z"
            ></path>
            <path
              id="cDGKFaLad"
              d="M318.55 242.21s-.17 8.72-1.2 10.08c0 0-10.17 6.22-10.63 8.44 0 0 1.11 5.31 5.47 4.2l14.85-7.99v-18.87"
            ></path>
            <path
              id="bckN02nqH"
              d="M353.56 252.14c.67 5.67 1.05 8.83 1.12 9.46 0 0-4 14.58-2.41 16.35 0 0 7.37 3.69 9.62 0l-.95-17.31c-.01-.68-.07-4.1-.16-10.26"
            ></path>
            <path
              id="aww9wTh1T"
              d="M320.7 205.18l-3.9 41.39s10.77 4.79 18.04 2.36c.37-2.89 2.26-17.31 5.67-43.27 3.81-27.21 5.93-42.32 6.36-45.34 0 0-11.63-11.57-14.34-8.3-2.36 10.64-11.34 46.07-11.83 53.16z"
            ></path>
            <path
              id="e19Oi1t4BZ"
              d="M354.29 162.89c8.01 33.94 8.86 59.58 13.02 89.26 0 0-17.34 6.33-21.17.2-2.59-30.25-4.68-60.58-5.65-90.92 2.76.29 11.04 1.17 13.8 1.46z"
            ></path>
            <path
              id="gmSp0Wddt"
              d="M364.21 131.38c-7.46-1-14.79-2.82-21.86-5.42-.96-.32-11.32-4.29-15.17-.87-3.84 3.42-2.82 14.55-2.82 14.55-1.4-1.21-12.59-10.91-13.99-12.12-5.19 1.86-7.25 7.23-7.75 12.56.06.06.48.5.54.55 1.5 1.77 2.74 3.19 3.04 3.54 4.59 5.16 9.85 11.11 16.61 13.36 2.66.78 5.52.61 8.07-.49 2.01-.75 4.95-3.19 7.09-2.39.57.21 1.1.62 1.77.55 1.62-.2 3.78-2.77 5.32-3.55 4.2-2.18 9.13-2.51 13.75-2.74 5.05-.18 10.08-.74 15.04-1.67 1.46-.3 16.94-4.27 17.28-3.54a53.672 53.672 0 01-5.03-16.82c-7.29 2.77-15.95 5.32-21.89 4.5z"
            ></path>
            <path
              id="a1oJfFEXus"
              d="M336.59 128.92c6.7.99 8.36-3.54 8.01-9.16-.28-4.45-2.61-11.41-8.61-9.39a6.168 6.168 0 00-3.37 3.1c-.48.96-.84 1.99-1.06 3.05-1.03 4.85-.16 11.57 5.03 12.4z"
            ></path>
            <path
              id="gDYdglygu"
              d="M327.02 110.15c.29-2.97 3.17-6.78 6.52-5.31 2.27.96 1.65 1.03 1.65 1.03a4.705 4.705 0 014.41-1.6c3.17.29 3.72 2.22 3.72 2.22s3.55 1.2 4.56 3.54c1.08 2.62-1.38 10.44-2.55 12.63-1.17 2.2-1.86-2.26-2.27-4.05a4.44 4.44 0 00-2.66-3.26c-1.95-.54-2.92 1.2-4.47 1.93-1.46.82-3.21.93-4.76.32a7.73 7.73 0 01-4.15-7.45z"
            ></path>
            <path
              id="d1MLKCses"
              d="M354.29 162.89c.05-1.49.28-8.9.71-22.24-16.08 7.54-25.02 11.72-26.81 12.56 0 0 1.01 5.32 2.15 10.3 4.37 1.22 8.93 1.66 13.46 1.29 3.59.03 7.15-.62 10.49-1.91z"
            ></path>
            <path
              id="a5zhUxhg2B"
              d="M136.23 285.2s14.31-11.48 23.81-12.85c0 0 14.46 8.97 13.79 15.86l-23.43 10.63"
            ></path>
            <path
              id="b57EZCpyGF"
              d="M143.85 289.03c1.77-1.78 26.15-13.06 34.71-7.89 8.56 5.17 8.02 9.02 6.94 15.33-1.08 6.31-9.3 40.27-9.3 40.27a30.83 30.83 0 01-19.49.92c.16-1.68.96-10.1 2.39-25.25l-32.37-17.71"
            ></path>
            <path
              id="a2i7KeGOJX"
              d="M140.82 255.8c2.28 5.11 5.84 31.49 5.84 31.49s-6.66 9.92-18.19 6.4c-3.42-1.05-21.16-33.48-21.64-36.84-1.22-8.75 19.3-8.44 19.3-8.44 1.29.96 12.4 2.29 14.69 7.39z"
            ></path>
            <path
              id="bpZCbH7G"
              d="M102.88 301.27c-2.71-10.63-4.5-50.69 13.48-49.06 19.74 1.77 13.08 51.22 33.09 49.7 20-1.53 28.2 3.68 20.02 11.44-16.3 15.52-55.18 15.84-65.21-7.97a29.61 29.61 0 01-1.38-4.11z"
            ></path>
            <path
              id="a7kxsMs4MD"
              d="M128.22 248.87c-3.94 3.03-12.94-4.12-13.17-7.69-.23-3.56-3.24-10.96.57-12.82 3.81-1.86 13.68-3.65 14.76 3.06.16.96 1.77 14.42-2.16 17.45z"
            ></path>
            <path
              id="b3iCduFVv"
              d="M134.31 226.5s-2.3-4.03-5.12-4.03c0 0-5.21.73-7.63-.72a8.616 8.616 0 00-8.54 0c-2.29 1.56-2.68 5.31-4.22 7.24-1.54 1.94-4.48 2.15-4.41 5.75.07 3.59 5.31 3.96 5.72 6.37.41 2.41 1.67 5.94 4.91 6.54 3.24.6 7.69.68 8.06.2.37-.48-2.04-8.54.19-9.07 2.24-.54 7.25-1.71 7.11-7.36.79-.98 3.35-2.59 3.93-4.92z"
            ></path>
            <path
              id="p29tleN5nk"
              d="M174.57 224.83c-.1-.11-.23-.2-.37-.27-.13-1.47-.53-2.94-1.77-3.45a1.16 1.16 0 00-.64 0 .998.998 0 00-1.16.8c-.01.07-.01.15-.01.23v1.59c-.14-.36-.39-.66-.73-.85-.42-.24-.94-.24-1.36 0-.49.07-.84.52-.8 1.01.03.68.24 6.08.27 6.75-.34-1.26-1.07-3.72-2.87-2.43-1.42 1.01-.39 3.8-.16 5.11.42 2.66 1.05 5.3 1.88 7.86.06.21.19.39.37.52-.09.41-.19.73-.22.81-.46 1.78-.93 4.15-2.3 5.32-1.04.9.48 2.43 1.52 1.52.01-.01.06-.05.15-.14-.36 1.15 1.43 1.95 1.96.83.02-.04.19-.36.22-.4.09.1.21.18.33.24 0 .24-.17.47-.25.7-.42 1.32 1.67 1.89 2.08.56.24.49.84.69 1.33.44.19-.09.34-.25.44-.44.25-.53.18 1.26.39.73.55.23-.6-1.77-.25-2.39 1.35-2.55 4.7-5.11 4.61-13.36.1-2.09 1.66-6.13-1.03-7.02a6.529 6.529 0 00-1.63-4.27z"
            ></path>
            <path
              id="b2IuKv8G3"
              d="M167.68 224.17c.19 4.31.52 7.74.58 8.6 0 .46.76.46.73 0-.25-2.86-.44-5.72-.59-8.6-.49-.3-.73-.3-.72 0z"
            ></path>
            <path
              id="b2zcA4JxhH"
              d="M171.24 222.03c0-.2-.16-.36-.36-.36-.2 0-.37.16-.37.36.03 1.16.26 10.4.29 11.55 0 .2.16.37.36.37.2 0 .36-.17.36-.37-.05-2.31-.22-9.24-.28-11.55z"
            ></path>
            <path
              id="ecSYfQObc"
              d="M173.49 234.03c0 .2.16.36.36.36.2 0 .37-.16.37-.36.02-.89.19-7.98.21-8.86 0-.2-.16-.37-.36-.37a.37.37 0 00-.37.37c-.04 1.77-.19 7.97-.21 8.86z"
            ></path>
            <path
              id="bwftT2G0g"
              d="M175.37 235.85c0 .46.65.46.72 0 .43-3.24.51-5.85.57-6.5 0-.48-.71-.46-.73 0-.15 3.26-.51 5.85-.56 6.5z"
            ></path>
            <path
              id="f36A24tb5k"
              d="M136.4 251.9s10.94 3.99 13.77 3.74l14.78-15.47s8.27 2.29 10.97 9.66c0 0-15.42 22.06-22.15 23.39-6.73 1.33-14.96-1.98-14.96-1.98"
            ></path>
            <path
              id="c8o2h38yC5"
              d="M206.04 313.18s-.51-19.3 8.27-19.62c0 0 20.29 6.01 20.91 16.21 0 0 2.36 29.31 2.36 32.89l-6.43 16.81"
            ></path>
            <path
              id="b6mLXdxH"
              d="M348.27 416.72c0 .64-.52 8.42.35 9.32.87.91 3.14 5.32 5.95 5.5 2.82.17 5.44-.38 5.96 2.64.51 3.01-15.95 4.04-15.95 4.04s-1.77-6.91-2.48-6.72c-.71.2-.71 7.62-.71 7.62-.2.04-1.22.26-3.06.66l-3.07-2.04 1.24-13.24 2-11.25"
            ></path>
            <path
              id="h1l921ogO"
              d="M360.63 427.83c-.4 4.68-.15 10.95 4.24 13.81 2.41 1.56 7.49.12 8.13 3.54.55 3.01-15.94 4.13-15.94 4.13s-1.78-6.89-2.52-6.7c-.74.2-.67 7.62-.67 7.62-.21.05-1.23.27-3.07.67l-3.06-2.02 1.15-13.25 1.93-11.27"
            ></path>
            <path
              id="a1C6GhVvQ"
              d="M355.55 339.86a1.776 1.776 0 00-1.6-2.68c.45-.79.79-1.63 1.01-2.51.13-.89-.49-1.72-1.38-1.85-.54-.07-1.09.13-1.45.54-.24.32-1.42 1.93-3.54 4.83 1.06-1.91 1.65-2.97 1.77-3.18 1.04-1.78-1.9-3.18-2.93-1.39-.2.36-1.24 2.13-3.1 5.32v-1.56c0-1.42-.28-3.23-2.09-3.31-.86-.08-1.65.47-1.88 1.31a7.564 7.564 0 000 3.54c.31 1.29 1.12 3.07.38 4.32-.37.58-3.3 5.14-3.67 5.71-.56.7-.44 1.71.26 2.27.23.18.51.3.8.33a1.664 1.664 0 002.22 1.4c.33.22.73.3 1.11.23.44.58 1.22.76 1.86.45.33.83 1.26 1.24 2.09.91.24-.09.45-.24.62-.43 2.61-3.14 6.06-5.46 8.44-8.86a1.772 1.772 0 00-1.08-2.5c.43-.58 1.94-2.6 2.16-2.89z"
            ></path>
            <path
              id="b29G3UULsh"
              d="M318.27 364.31a9.343 9.343 0 008.33 6.43c6.59.38 19.1-13.53 19.1-13.53s.33-12.41-5.12-14.55c0 0-9.06 7.81-10.32 7.9-1.25.09-8.59-6.8-8.59-6.8"
            ></path>
            <path
              id="b8ICrbYpd"
              d="M357.98 340.68c.34-.92-.12-1.94-1.04-2.29-.31-.11-.65-.14-.98-.07.3-.86.49-1.75.56-2.66-.02-.9-.77-1.61-1.67-1.58-.55.02-1.05.3-1.34.77-.18.35-1.1 2.12-2.74 5.31.75-2.07 1.17-3.22 1.25-3.45.71-1.97-2.41-2.8-3.12-.87-.14.39-.85 2.34-2.14 5.85-.2-.92-.31-1.43-.34-1.53-.3-1.4-.83-3.12-2.62-2.9-.86.07-1.54.75-1.61 1.61-.06 1.22.18 2.43.69 3.54.51 1.22 1.63 2.84 1.1 4.2-.26.63-2.38 5.63-2.64 6.26-.42.78-.13 1.76.66 2.18.25.14.54.2.83.19a1.67 1.67 0 002.06 1.14c.12-.04.24-.09.35-.15.36.14.77.14 1.13 0 .53.5 1.33.55 1.91.13.46.76 1.45 1.01 2.22.55.22-.14.41-.33.55-.55 2.04-3.55 5.03-6.42 6.8-10.14.32-.92-.17-1.93-1.1-2.25a1.58 1.58 0 00-.42-.09c.33-.64 1.48-2.88 1.65-3.2z"
            ></path>
            <path
              id="ahJtrnRlS"
              d="M333.61 410.74c-1.45 7.95-2.26 12.37-2.42 13.25 0 0 3.81 3.91 16.63 3.45v-23.42"
            ></path>
            <path
              id="c5nAMrpCY"
              d="M335.23 441.64c0 3.74-8.18 6.78-18.27 6.78s-18.27-3.04-18.27-6.78c0-3.75 8.18-6.79 18.27-6.79s18.27 3.04 18.27 6.79z"
            ></path>
            <path id="cicpAQddn" d="M313.88 411.28l.7 27.31h5.44v-27.31"></path>
            <path
              id="h3prsYvkpc"
              d="M335.53 374.64c-.25.02-.48.14-.64.34-.96-2.89-1.47-5.28-1.63-5.87-.25-1.13-1.99-.65-1.77.48 1.02 4.52 2.54 8.9 4.53 13.08.5 1.04 2.04.14 1.56-.9-.83-1.78-1.56-3.55-2.21-5.32h.12c8.58-1.01 15.33-2.55 17.03-2.84 1.17-.26.69-2-.44-1.75-8.21 1.77-14.9 2.5-16.55 2.78z"
            ></path>
            <path
              id="a10ughQXbQ"
              d="M331.75 390.89c-.9.73.38 2 1.28 1.28a25.69 25.69 0 005.83-6.72.91.91 0 00-.32-1.24.91.91 0 00-1.24.32 24.117 24.117 0 01-5.55 6.36z"
            ></path>
            <path
              id="b2rtpoGL3"
              d="M326.1 378.68s14.32-11.48 23.81-12.84c0 0 14.46 8.96 13.79 15.85l-23.42 10.64"
            ></path>
            <path
              id="b8S6FMwZiK"
              d="M333.74 382.51c1.77-1.77 26.15-13.06 34.71-7.89 8.56 5.18 8.03 9.02 6.94 15.33-1.08 6.31-9.35 40.29-9.35 40.29a30.833 30.833 0 01-19.49.92c.16-1.68.96-10.09 2.39-25.24l-32.37-17.72"
            ></path>
            <path
              id="d49mq1lsT"
              d="M330.71 349.3c2.28 5.11 5.84 31.49 5.84 31.49s-6.66 9.92-18.19 6.4c-3.42-1.05-21.16-33.47-21.64-36.84-1.22-8.77 19.3-8.43 19.3-8.43 1.29.95 12.4 2.28 14.69 7.38z"
            ></path>
            <path
              id="b548wMY6SM"
              d="M292.77 394.77c-2.71-10.63-4.5-50.69 13.49-49.06 19.73 1.77 13.07 51.22 33.08 49.7 20-1.53 28.2 3.68 20.02 11.44-16.34 15.51-55.18 15.93-65.21-7.97a29.61 29.61 0 01-1.38-4.11z"
            ></path>
            <path
              id="cLFdm39Np"
              d="M321.33 343c-3.33 2.58-11.02-3.55-11.19-6.54-.18-3-2.77-9.34.47-10.92 3.25-1.57 11.59-3.08 12.5 2.59.12.82 1.56 12.28-1.78 14.87z"
            ></path>
            <path
              id="bgQkYvA0Y"
              d="M326.1 371.75a9.352 9.352 0 009.43 4.7c6.54-.9 16.12-16.98 16.12-16.98s-2.07-12.24-7.83-13.28c0 0-7.37 9.4-8.59 9.74-1.23.34-4.5-6.61-4.5-6.61"
            ></path>
            <path
              id="e11YEQGyM"
              d="M319.35 343.33c.39-1.77 2.13-2.94 2.18-4.75.03-1.75-.64-3.45-1.86-4.71-.76-.78-1.77-1.22-1.19-2.46.44-.81 1.1-1.47 1.9-1.92.37.03 3.35.21 3.72.24 1.44-4.03-.38-8.5-4.22-10.39a13.493 13.493 0 00-15.11 1.77c-.98 1.08-1.81 2.27-2.5 3.55-.8 1.6-1.9 3.03-3.24 4.21-1.5.89-2.52 2.41-2.77 4.13-.11 1.66.34 3.3 1.26 4.68.94 1.77-.57 3.01-1.77 4.29a7.476 7.476 0 00-2.46 4.59c-.18 3.84 3.54 5.61 6.75 6.2 3.2.58 6 1.01 9.02 1.52 3.82.66 7.56 2.04 11.48.98 1.02-.29 1.96-.83 2.73-1.58a4.177 4.177 0 00-.89-6.55c-1.45-1-3.54-1.78-3.03-3.8z"
            ></path>
            <path
              id="g1fI6K4jfP"
              d="M263.18 313.41c.6-.36.81-1.13.45-1.73-.35-.6-1.12-.81-1.72-.45-.03.01-.28.16-.31.17.01-.7-.56-1.28-1.26-1.28-.21 0-.43.05-.61.15-1.46.71-2.89 1.44-4.31 2.2.06-.07.5-.61.55-.68.83-1.04-.21-2.8-1.52-1.98-2.59 1.61-4.58 3.03-5.09 3.37-.6.42-.74 1.25-.31 1.85.01.02.03.04.05.06-.03.02-.24.18-.27.19-.55.42-.71 1.18-.37 1.78-.13.38-.08.8.12 1.15-.78.58-.34 1.77.52 2.05 0 .01.04.05.1.13-.89.31-1.39.48-1.49.51-1.52.53-.86 2.96.68 2.43.76-.27 6.87-2.41 7.63-2.68 2-.49 3.83-1.49 5.32-2.9.35-.37.44-.92.21-1.38.55-.29 1.07-.64 1.54-1.05.49-.39.57-1.1.18-1.59-.07-.09-.15-.17-.25-.24.03-.01.15-.07.16-.08z"
            ></path>
            <path
              id="e3wmGBpeGg"
              d="M231.8 318.24s13.73-5.58 17.83-6.78c0 0 4.23 2.97 3.06 10.79 0 0-12.4 12.65-20.06 16.46"
            ></path>
            <path
              id="c39hoI97pd"
              d="M221.03 289.13a5.156 5.156 0 00-2.15-1.77c-1-.31-2.08.25-2.39 1.26l-.03.12a3.208 3.208 0 002.79 3.58c.33.04.67.03 1-.03.66-.2 1.05-.88.89-1.55.35-.45.31-1.15-.11-1.61z"
            ></path>
            <path
              id="c4H5yo8wqD"
              d="M241.37 398.6c0 3.75-8.18 6.78-18.27 6.78s-18.27-3.03-18.27-6.78c0-3.75 8.18-6.79 18.27-6.79s18.27 3.04 18.27 6.79z"
            ></path>
            <path id="dF9ER9Cpl" d="M220.04 368.25l.71 27.3h5.44v-27.3"></path>
            <path
              id="c6ShxjAlRC"
              d="M196.74 348.76c-2.71-10.64-4.5-50.7 13.48-49.07 19.74 1.77 13.08 51.23 33.08 49.7 20.01-1.52 28.21 3.69 20.02 11.45-16.24 15.5-55.23 15.88-65.2-7.97-.56-1.34-1.03-2.71-1.38-4.11z"
            ></path>
            <path
              id="a197x3Zf0"
              d="M226.29 294.32c-3.06 2.89-9.12 2.7-12.28-2.6-3.15-5.3 4.02-17.77 10.03-15.33.45 3.59 6.57 13.86 2.25 17.93z"
            ></path>
            <path
              id="dM1kZhtC3"
              d="M217.57 289.65c3.52-3.2 5.79-7.54 6.42-12.25.71-7.4-15.65-4.78-18.32 2.39-2.68 7.18 1.27 17.6 12.28 16.13-.08-1.26-.3-5.02-.38-6.27z"
            ></path>
            <path
              id="b8qG848io"
              d="M209.97 290.51c-1.13.96-29.8 30.25-14.93 40.07 12.4 8.18 17.15-34.72 17.13-37.99"
            ></path>
            <path
              id="b1Uc7cArR"
              d="M588.28 343.35a6.598 6.598 0 00-1.64-1.43c-8.35-5.66-19.9-7.22-28.74-1.78-2.02 1.26-5.02 4.13-3.83 6.83.69 1.55 2.78 2.48 4.23 3.11 4.86 1.93 9.93 3.25 15.1 3.94 1.88.32 3.76.62 5.65.81a7.79 7.79 0 006.88-2.96c1.34-1.57 3.49-4.96 2.89-7.4-.1-.41-.28-.79-.54-1.12z"
            ></path>
            <path
              id="b1PcQDhBLd"
              d="M574.64 351.7c13.15-1.01 14.18-7.23 14.18-7.23s-3.41 43.6-5.21 44.29c-1.81.7-8.53 3.99-11.77 4.24s-13.5-3.86-13.75-5c-.25-1.13-4.22-42.19-4.22-42.19a29.037 29.037 0 0020.77 5.89z"
            ></path>
            <path
              id="b1rvvUkwMg"
              d="M576.48 333.68c-.21.47-.34.97-.37 1.48l.37-1.48z"
            ></path>
            <path
              id="b51aQXdH8v"
              d="M555.03 283c9 18.39 10.75 39.09 12.4 59.18 0 .92 1.51.92 1.43 0-1.64-20.39-3.47-41.27-12.59-59.91-.41-.83-1.65-.1-1.24.73z"
            ></path>
            <path
              id="g5Vym8ZDrM"
              d="M555.13 296.77s-4.75 5.72-1.64 11.68c0 0 11.39 6.71 11.94 11.26.55 4.56-4.06-29.16-10.3-37.26 0 2.87 2.52 9.53 0 14.32z"
            ></path>
            <path
              id="i6X18r4MvA"
              d="M572.5 346.59c0 1.14 1.77 1.14 1.77 0a119.23 119.23 0 0111.23-46.07c.41-.97-1.1-1.86-1.57-.85a121.167 121.167 0 00-11.43 46.92z"
            ></path>
            <path
              id="e78sGCpNJX"
              d="M585.04 299.82c-.1.06-.91.57-1.01.63-3.42 8.42-8.52 28.21-10.06 34.27 2.07-4.53 10.63-5.15 13.09-11.67 1.1-2.75 1.15-5.81.14-8.6a32.112 32.112 0 01-2.05-10.12c0-.95-.21-2.83-.11-4.51z"
            ></path>
          </defs>
          <use fill="#ffc963" xlinkHref="#fVpncTD1"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#fVpncTD1"
          ></use>
          <use fill="url(#gradiente1lLiAnE7V)" xlinkHref="#aoqTt5NIe"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#aoqTt5NIe"
          ></use>
          <use fill="url(#gradientc3bS4XJOAa)" xlinkHref="#a5malkJjIo"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#a5malkJjIo"
          ></use>
          <use fill="url(#gradienta3dqXHL3cm)" xlinkHref="#alJ6j40aN"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#alJ6j40aN"
          ></use>
          <use fill="url(#gradientc5HvZYdSB)" xlinkHref="#b1ir0iGkc"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#b1ir0iGkc"
          ></use>
          <use fill="url(#gradientb8vjqWlNh)" xlinkHref="#a1vXhXEgFy"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#a1vXhXEgFy"
          ></use>
          <use fill="#007058" xlinkHref="#b9sTtx8KL"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#b9sTtx8KL"
          ></use>
          <use fill="#ff666d" xlinkHref="#a1gAxzjMuA"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#a1gAxzjMuA"
          ></use>
          <use fill="#edbab9" xlinkHref="#e2kxDlCZGK"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#e2kxDlCZGK"
          ></use>
          <use fill="#edbab9" xlinkHref="#c1lqxiZUpv"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#c1lqxiZUpv"
          ></use>
          <use fill="#007058" xlinkHref="#d3rB3QfWWi"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#d3rB3QfWWi"
          ></use>
          <use fill="#ff666d" xlinkHref="#a73wmlhbUc"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#a73wmlhbUc"
          ></use>
          <use fill="#edbab9" xlinkHref="#djs1en5b"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#djs1en5b"
          ></use>
          <use fill="#ffe0de" xlinkHref="#b3wAoFbbq8"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#b3wAoFbbq8"
          ></use>
          <use fill="#007058" xlinkHref="#aEzXLbFQ5"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#aEzXLbFQ5"
          ></use>
          <use fill="#ff666d" xlinkHref="#bQfTT82rM"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#bQfTT82rM"
          ></use>
          <use fill="#edbab9" xlinkHref="#gACAZGq84"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#gACAZGq84"
          ></use>
          <use fill="#008074" xlinkHref="#b1oArbGO4M"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#b1oArbGO4M"
          ></use>
          <use fill="#008074" xlinkHref="#f4i6siBwAJ"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#f4i6siBwAJ"
          ></use>
          <use fill="#ff666d" xlinkHref="#e13gRBqPhf"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#e13gRBqPhf"
          ></use>
          <use fill="#4be2c5" xlinkHref="#eNAHD5kAV"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#eNAHD5kAV"
          ></use>
          <use fill="#ff666d" xlinkHref="#cbBoxRNNm"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#cbBoxRNNm"
          ></use>
          <use fill="#007058" xlinkHref="#cIZAZrJ8A"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#cIZAZrJ8A"
          ></use>
          <use fill="#68e1fd" xlinkHref="#b24y1pIgvx"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#b24y1pIgvx"
          ></use>
          <use fill="#ff666d" xlinkHref="#g2hXsoigfj"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#g2hXsoigfj"
          ></use>
          <use fill="#007058" xlinkHref="#doPCy5CVu"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#doPCy5CVu"
          ></use>
          <use fill="#007058" xlinkHref="#k2L5HBiRVF"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#k2L5HBiRVF"
          ></use>
          <use fill="#007058" xlinkHref="#a6IgRkC69j"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#a6IgRkC69j"
          ></use>
          <use fill="#007058" xlinkHref="#b74QoBlbGI"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#b74QoBlbGI"
          ></use>
          <use fill="#68e1fd" xlinkHref="#bkhmI038r"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#bkhmI038r"
          ></use>
          <use fill="#68e1fd" xlinkHref="#c1UpaucV2E"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#c1UpaucV2E"
          ></use>
          <use fill="#007058" xlinkHref="#d2i8ENPMLJ"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#d2i8ENPMLJ"
          ></use>
          <use fill="#008074" xlinkHref="#delUDozIL"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#delUDozIL"
          ></use>
          <use fill="#008074" xlinkHref="#cLFpYnzM4"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#cLFpYnzM4"
          ></use>
          <use fill="#008074" xlinkHref="#a9uBzBoNoM"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#a9uBzBoNoM"
          ></use>
          <use fill="#007058" xlinkHref="#cDGKFaLad"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#cDGKFaLad"
          ></use>
          <use fill="#007058" xlinkHref="#bckN02nqH"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#bckN02nqH"
          ></use>
          <use fill="#ff666d" xlinkHref="#aww9wTh1T"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#aww9wTh1T"
          ></use>
          <use fill="#ff666d" xlinkHref="#e19Oi1t4BZ"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#e19Oi1t4BZ"
          ></use>
          <use fill="#ffc963" xlinkHref="#gmSp0Wddt"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#gmSp0Wddt"
          ></use>
          <use fill="#ff666d" xlinkHref="#a1oJfFEXus"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#a1oJfFEXus"
          ></use>
          <use fill="#007058" xlinkHref="#gDYdglygu"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#gDYdglygu"
          ></use>
          <use fill="#ffc963" xlinkHref="#d1MLKCses"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#d1MLKCses"
          ></use>
          <use fill="#edbab9" xlinkHref="#a5zhUxhg2B"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#a5zhUxhg2B"
          ></use>
          <use fill="#ffe0de" xlinkHref="#b57EZCpyGF"></use>
          <use
            fillOpacity="0"
            stroke="#000"
            strokeOpacity="0"
            xlinkHref="#b57EZCpyGF"
          ></use>
          <g>
            <use fill="#4be2c5" xlinkHref="#a2i7KeGOJX"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#a2i7KeGOJX"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#bpZCbH7G"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#bpZCbH7G"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#a7kxsMs4MD"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#a7kxsMs4MD"
            ></use>
          </g>
          <g>
            <use fill="#008074" xlinkHref="#b3iCduFVv"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#b3iCduFVv"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#p29tleN5nk"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#p29tleN5nk"
            ></use>
          </g>
          <g>
            <use fill="#c1444d" xlinkHref="#b2IuKv8G3"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#b2IuKv8G3"
            ></use>
          </g>
          <g>
            <use fill="#c1444d" xlinkHref="#b2zcA4JxhH"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#b2zcA4JxhH"
            ></use>
          </g>
          <g>
            <use fill="#c1444d" xlinkHref="#ecSYfQObc"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#ecSYfQObc"
            ></use>
          </g>
          <g>
            <use fill="#c1444d" xlinkHref="#bwftT2G0g"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#bwftT2G0g"
            ></use>
          </g>
          <g>
            <use fill="#4be2c5" xlinkHref="#f36A24tb5k"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#f36A24tb5k"
            ></use>
          </g>
          <g>
            <use fill="#4be2c5" xlinkHref="#c8o2h38yC5"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#c8o2h38yC5"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#b6mLXdxH"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#b6mLXdxH"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#h1l921ogO"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#h1l921ogO"
            ></use>
          </g>
          <g>
            <use fill="#e84f5b" xlinkHref="#a1C6GhVvQ"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#a1C6GhVvQ"
            ></use>
          </g>
          <g>
            <use fill="#3accb0" xlinkHref="#b29G3UULsh"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#b29G3UULsh"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#b8ICrbYpd"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#b8ICrbYpd"
            ></use>
          </g>
          <g>
            <use fill="#edbab9" xlinkHref="#ahJtrnRlS"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#ahJtrnRlS"
            ></use>
          </g>
          <g>
            <use fill="#008074" xlinkHref="#c5nAMrpCY"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#c5nAMrpCY"
            ></use>
          </g>
          <g>
            <use fill="#008074" xlinkHref="#cicpAQddn"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#cicpAQddn"
            ></use>
          </g>
          <g>
            <use fill="#008074" xlinkHref="#h3prsYvkpc"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#h3prsYvkpc"
            ></use>
          </g>
          <g>
            <use fill="#008074" xlinkHref="#a10ughQXbQ"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#a10ughQXbQ"
            ></use>
          </g>
          <g>
            <use fill="#edbab9" xlinkHref="#b2rtpoGL3"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#b2rtpoGL3"
            ></use>
          </g>
          <g>
            <use fill="#ffe0de" xlinkHref="#b8S6FMwZiK"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#b8S6FMwZiK"
            ></use>
          </g>
          <g>
            <use fill="#4be2c5" xlinkHref="#d49mq1lsT"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#d49mq1lsT"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#b548wMY6SM"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#b548wMY6SM"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#cLFdm39Np"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#cLFdm39Np"
            ></use>
          </g>
          <g>
            <use fill="#4be2c5" xlinkHref="#bgQkYvA0Y"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#bgQkYvA0Y"
            ></use>
          </g>
          <g>
            <use fill="#008074" xlinkHref="#e11YEQGyM"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#e11YEQGyM"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#g1fI6K4jfP"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#g1fI6K4jfP"
            ></use>
          </g>
          <g>
            <use fill="#4be2c5" xlinkHref="#e3wmGBpeGg"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#e3wmGBpeGg"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#c39hoI97pd"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#c39hoI97pd"
            ></use>
          </g>
          <g>
            <use fill="#008074" xlinkHref="#c4H5yo8wqD"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#c4H5yo8wqD"
            ></use>
          </g>
          <g>
            <use fill="#008074" xlinkHref="#dF9ER9Cpl"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#dF9ER9Cpl"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#c6ShxjAlRC"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#c6ShxjAlRC"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#a197x3Zf0"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#a197x3Zf0"
            ></use>
          </g>
          <g>
            <use fill="#007058" xlinkHref="#dM1kZhtC3"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#dM1kZhtC3"
            ></use>
          </g>
          <g>
            <use fill="#007058" xlinkHref="#b8qG848io"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#b8qG848io"
            ></use>
          </g>
          <g>
            <use fill="#007058" xlinkHref="#b1Uc7cArR"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#b1Uc7cArR"
            ></use>
          </g>
          <g>
            <use fill="#4be2c5" xlinkHref="#b1PcQDhBLd"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#b1PcQDhBLd"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#b1rvvUkwMg"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#b1rvvUkwMg"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#b51aQXdH8v"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#b51aQXdH8v"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#g5Vym8ZDrM"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#g5Vym8ZDrM"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#i6X18r4MvA"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#i6X18r4MvA"
            ></use>
          </g>
          <g>
            <use fill="#ff666d" xlinkHref="#e78sGCpNJX"></use>
            <use
              fillOpacity="0"
              stroke="#000"
              strokeOpacity="0"
              xlinkHref="#e78sGCpNJX"
            ></use>
          </g>
        </svg>
      </span>
    </div>
  );
}

export default SearchMediaIcon;